//       SUMMARY TABLE     
// =========================
// 1. Global
// =========================


// 1. Global
// =========================

* {
  box-sizing: border-box;
  outline: none;
}

html {
  background-color: #fff;
  overflow-x: hidden;
}

body {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  overflow-x: hidden;
  @include transition(background-color 1s $smooth);
}

img {
  max-width: 100%;
}

::-moz-selection {
  background: #000;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: #000;
  color: #fff;
  text-shadow: none;
}

.container {
  padding: 0 1.5rem;
}

canvas {
  display: block;
  height: 100vh;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 6;
}