gl-wave-effect {
  position: fixed;
  inset: 0;
  z-index: 100;
  pointer-events: none;
  z-index: 1;
}

.in-wave {
  header.header {
    opacity: 1 !important;
  }

  .home__content {
    opacity: 1 !important;
    transform: scale(1) !important;
  }
}
