//       SUMMARY TABLE     
// =========================
// 1. Global
// =========================


// 1. Global
// =========================

.page.home {
	padding-top: 0;
  height: 100vh;
  overflow: hidden;

	#lottie {
    width:120%;
    height:120%;
    display:block;
    overflow: hidden;
    transform: translate3d(0,0,0);
    text-align: center;
    opacity: 1;
    position: fixed;
    top: -10%;
    left: -10%;
    pointer-events: none;
    z-index: 2;

    svg {
      g:first-child rect {
        display: none;
      }
    }
	}
}

.home {

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #000;
    opacity: 0;
    pointer-events: none;
    z-index: 2;
  }

  &.fade-black {
    &:after {
      opacity: 1;
      @include transition(opacity 3s $smooth 0.2s);
    }
  }


  &__content {
    height: 100%;
    opacity: 0;

    &__head {
      display: flex;
      height: calc(36vh - 1.5rem);
      align-items: center;
      position: relative;
      justify-content: center;

      .design {
        position: absolute;
        font-family: 'TimesNow';
        font-size: 1.3rem;
        line-height: 1;
        color: rgba(0, 0, 0, 0.85);
        top: 2.4rem;
        left: 50%;
        @include transform(translateX(-50%));

        @include breakpoint(991px) {
          font-size: 1.2rem;
          top: 2rem;
        }
      }

      .content {
        text-align: center;

        h2 {
          font-family: 'TimesNow';
          font-size: 3.7rem;
          letter-spacing: -0.1rem;
          line-height: 1;
          margin-top: 0.6rem;
          font-weight: 300;

          @include breakpoint(991px) {
            font-size: 2.4rem;
          }
        }

        img {
          @include breakpoint(991px) {
            width: 4.5rem;
          }
        }
      }

      .home-play {
        background: transparent;
        border: none;
        appearance: none;
        padding: 0;
        position: absolute;
        bottom: 2rem;
        left: 0;
        cursor: pointer;
        font-size: 1.5rem;
        line-height: 1;
        color: #A9A9A9;
        font-family: 'TimesNow';

        @media screen and (min-width: 992px) {
          @include transition(color 0.4s $smooth);

          &:hover {
            color: #000;
          }
        }

        @include breakpoint(991px) {
          bottom: 2.4rem;
        }
      }

      .home-switch {
        background: transparent;
        border: none;
        cursor: pointer;
        height: 3.2rem;
        width: 3.2rem;
        position: absolute;
        right: -0.4rem;
        bottom: 2rem;
        appearance: none;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        z-index: 4;

        @include breakpoint(1500px) {
          height: 2.8rem;
          width: 2.8rem;
        }

        @include breakpoint(991px) {
          height: 2.6rem;
          width: 2.6rem;
          bottom: 2rem;
        }

        svg {
          display: block;

          path {
            @include transition(fill 2.5s $smooth);
          }

          @include breakpoint(991px) {
            height: 1.2rem;
            width: 1.2rem;
          }
        }

        &.close {
          svg {
            path {
              fill: #000;
            }
          }
        }
      }

      h1 {
        font-size: 5.4rem;
        letter-spacing: -1.5px;
        line-height: 1;
        font-family: 'Review';
        display: flex;

        span {
          display: block;
          margin-right: 1rem;
        }

        @include breakpoint(1500px) {
          font-size: 4.6rem;
        }

        @include breakpoint(991px) {
          font-size: 3.4rem;

          span {
            margin-right: 0.5rem;
          }
        }
      }
    }

    &__video {
      height: 64vh;
      overflow: hidden;
      position: absolute;
      bottom: 1.5rem;
      width: calc(100% - 3rem);
      left: 1.5rem;

      video {
        display: block;
        height: 100vh;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  &__info {
    padding: 15vh 0;

    h2 {
      font-size: 5.2rem;
      text-align: center;
      letter-spacing: -1.6px;
      width: 900px;
      max-width: 80%;
      font-family: 'Saans';
      line-height: 1.4;
      margin: 0 auto;

      @include breakpoint(991px) {
        font-size: 3rem;
        letter-spacing: -1px;
        width: 80%;
      }
    }
  }
}

.studio__video {
  margin-top: 5vh;
  height: 100vh;
  width: 100%;
  opacity: 0;
  @include transform(translateY(20vh));

  video {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.loop {
  height: 100vh;
  padding-top: 17vh;
  overflow: hidden;
}