@font-face {
    font-family: 'SaansMediumItalic';
    src: url('../fonts/SaansTRIAL-MediumItalic.woff2') format('woff2'),
        url('../fonts/SaansTRIAL-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SaansMedium';
    src: url('../fonts/SaansTRIAL-Medium.woff2') format('woff2'),
        url('../fonts/SaansTRIAL-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Saans';
    src: url('../fonts/SaansTRIAL-Regular.woff2') format('woff2'),
        url('../fonts/SaansTRIAL-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SaansItalic';
    src: url('../fonts/SaansTRIAL-RegularItalic.woff2') format('woff2'),
        url('../fonts/SaansTRIAL-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SaansBold';
    src: url('../fonts/SaansTRIAL-Bold.woff2') format('woff2'),
        url('../fonts/SaansTRIAL-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SaansLight';
    src: url('../fonts/SaansTRIAL-Light.woff2') format('woff2'),
        url('../fonts/SaansTRIAL-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SaansBoldItalic';
    src: url('../fonts/SaansTRIAL-BoldItalic.woff2') format('woff2'),
        url('../fonts/SaansTRIAL-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}


@font-face {
    font-family: 'Alfredino';
    src: url('../fonts/TRIALAlfredinoSemimono-Semimono.woff2') format('woff2'),
        url('../fonts/TRIALAlfredinoSemimono-Semimono.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NTBauMono';
    src: url('../fonts/NTBauMono-Regular.woff2') format('woff2'),
        url('../fonts/NTBauMono-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Review';
    src: url('../fonts/Review-Medium.woff2') format('woff2'),
        url('../fonts/Review-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'TimesNow';
    src: url('../fonts/TimesNow-SemiLight.woff2') format('woff2'),
        url('../fonts/TimesNow-SemiLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

