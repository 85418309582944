//       SUMMARY TABLE     
// =========================
// 1. Global
// =========================


// 1. Global
// =========================

.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	padding: 1.6rem 1.5rem 0 1.5rem;
	z-index: 10;
	pointer-events: none;
	opacity: 0;

	h1 {
		height: 3rem;
		width: 3rem;
		margin-top: -0.7rem;

		#logo-lottie {
			height: 3rem;
			width: 3rem;
		}
	}

	.mobile {
		display: none;

		@include breakpoint(991px) {
			display: block;
		}
	}

	.desktop {
		@include breakpoint(991px) {
			display: none;
		}
	}

	&__links {
		position: absolute;
		top: 3.2rem;
		left: 59.6%;
		color: #111;
		font-size: 1.1rem;
		line-height: 1;
		letter-spacing: -0.22px;
		font-family: 'SaansMedium';

		a {
			color: #111;
			pointer-events: initial;

			&:visited, &:active {
				color: #111!important;
				text-decoration: none!important;
			}
		}

		@include breakpoint(991px) {
			display: none;
		}
	}

	@include breakpoint(991px) {
		padding-top: 1.6rem;
	}

	h1 {
		a {
			pointer-events: initial;
		}
	}

	&__menu {
		position: absolute;
		right: 1.5rem;
		display: flex;
		top: 2rem;
		z-index: 12;
		pointer-events: initial;

		@include breakpoint(1500px) {
			font-size: 1.5rem;
		}

		@include breakpoint(991px) {
			top: 1.4rem;
		}

		a {
			color: #A9A9A9;
			display: flex;
			align-items: center;
			font-family: 'TimesNow';
			font-size: 1.5rem;
			@include transition(color 0.3s $smooth);

			path {
				@include transition(fill 0.3s $smooth);
			}

			span {
				margin-left: 1rem;
			}

			@include breakpoint(991px) {
				font-size: 1.4rem;
				letter-spacing: -0.5px;
			}

			&:hover {
				color: #000;

				path {
					fill: #000;
				}
			}
		}

		&__work {
			svg {
				opacity: 0;
				visibility: hidden;
				@include transform(rotate(-45deg));
				@include transition(all 1s $smooth);
			}
		}

		&.case-study {
			.header__menu__work svg {
				opacity: 1;
				visibility: visible;
				@include transform(rotate(0));
			}
		}

		&.work-menu {
			.header__menu__work {
				color: #000;
			}
		}
	}

	&.nopointer {
		h1 {
			a {
				pointer-events: none;
			}
		}

		.header__menu {
			pointer-events: none;
		}
	}
}










