//       SUMMARY TABLE     
// =========================
// 1. Global
// =========================


// 1. Global
// =========================

*::-webkit-scrollbar {
	display: none;
}

.work {
	padding-top: 0;

	&:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #000;
    opacity: 0;
    pointer-events: none;
    z-index: 2;
  }

  &.fade-black {
    &:before {
      opacity: 1;
      @include transition(opacity 3s $smooth 0.2s);
    }
  }

	.title {
		position: relative;
		font-family: 'TimesNow';
		font-size: 1.5rem;
		letter-spacing: 0;
	}
	

	gl-wave-effect {
		position: fixed;
		z-index: 0;
	}

	.title-wrapper {
		display: flex;
    height: 32.5vh;
    align-items: flex-end;
    position: relative;

    @include breakpoint(991px) {
    	height: 30rem;
    }

    .content {
    	position: absolute;
    	top: 50%;
    	left: 0;
    	width: 87.1rem;
    	@include transform(translate(0, -50%));

    	@include breakpoint(991px) {
    		width: 100%;
    	}

      h2 {
        font-family: 'TimesNow';
        font-size: 4.2rem;
        letter-spacing: -0.084rem;
        line-height: 4.6rem;

        @include breakpoint(991px) {
          font-size: 2.4rem;
          line-height: 2.6rem;
          max-width: 34rem;
          letter-spacing: -0.06rem;
        }
      }
    }
	}

	&:after {
		content: '';
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: #fff;
		z-index: 20;
		pointer-events: none;

		@include breakpoint(991px) {
			display: none;
		}
	}

	&.ready {
		&:after {
			opacity: 0;
			visibility: hidden;
			@include transition(all 1s $smooth 0.2s);
		}
	}

	&.notransition {
		&:after {
			display: none;
		}
	}

	.grid {
		display: flex;
		padding: 2rem 0 15rem 0;
		flex-wrap: wrap;

		@include breakpoint(991px) {
			padding: 2rem 0 0 0;
		}

		&__item {
			position: relative;

			&__link {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				z-index: 5;
			}

			.image {
				width: 100%;
				position: relative;
				overflow: hidden;
			}

			.img-wrap {
				height: 100%;
				width: 100%;
				@include transition(transform 3s $smooth);
			}

			.img {
				height: 100%;
				background-color: #D9D9D9;
				opacity: 1;
				position: relative;
				z-index: 1;
			}

			@media screen and (min-width: 992px) {
				&:hover {
					.img-wrap {
						@include transform(scale(1.1));
					}
				}
			}

			.transition {
				position: absolute;
				background-color: #fff;
				top: 0;
				left: 0;
				padding-top: 12rem;
				width: 100%;
				height: 100%;
				overflow: hidden;

				@include breakpoint(991px) {
					padding-top: 10rem;
				}

				.head {
					display: flex;
					justify-content: space-between;
					align-items: center;
					text-align: center;
					width: 100%;
					height: calc(65vh - 24rem);

					@include breakpoint(991px) {
						flex-wrap: wrap;
						justify-content: center;
						height: calc(80vh - 24rem);
					}

					.content {

						img {
							display: table;
							margin: 0 auto;

							@include breakpoint(991px) {
								width: 4.5rem;
							}
						}
					}

					h1 {
						font-family: 'TimesNow';
						font-size: 13.2rem;
						letter-spacing: -0.6px;
						line-height: 1;
						position: relative;
						color: #1E1E1E;

						.new {
							font-size: 1rem;
							border-radius: 9px;
							border: 1px solid #000;
							padding: 0 7px;
							letter-spacing: -0.05px;
							display: inline-flex;
							align-items: center;
							height: 18px;
							position: absolute;
							font-family: 'Saans';
							text-transform: uppercase;
							top: 2rem;
							margin-left: 2rem;
						}

						@include breakpoint(991px) {
							font-size: 4.8rem;
							text-align: center;
							line-height: 2.8rem;


							& > span:not(.new) {
								display: block;
							}

							.new {
								position: relative;
								top: auto;
								margin-left: 0;
							}
						}
					}

					// h1 {
					// 	font-family: 'SaansMedium';
					// 	font-size: 4rem;
					// 	line-height: 1;
					// 	letter-spacing: -5px;
					// 	display: flex;
					// 	align-items: flex-start;

					// 	sup {
					// 		font-size: 1.8rem;	
					// 		margin-left: 2rem;
					// 		letter-spacing: 0;
					// 		font-family: 'Saans';
					// 	}

					// 	@include breakpoint(991px) {
					// 		font-size: 3rem;
					// 		letter-spacing: 0;

					// 		sup {
					// 			font-size: 1.2rem;
					// 			margin-left: 1rem;
					// 		}
					// 	}
					// }
				}
			}

			.caption {
				display: flex;
				justify-content: space-between;
				padding-top: 1.4rem;

				@include breakpoint(991px) {
					align-items: center;
				}

				h4 {
					font-size: 1.2rem;

					@include breakpoint(991px) {
						font-size: 1.6rem;
					}

					span {
						font-size: 9px;
						color: rgba(0, 0, 0, 0.50);
						margin-left: 1.6rem;
						letter-spacing: -0.045px;
						text-transform: uppercase;

						@include breakpoint(991px) {
							display: none;
						}
					}
				}

				p {
					line-height: 1.2;

					@include breakpoint(991px) {
						display: none;
					}
				}

				.category {
					color: rgba(0, 0, 0, 0.50);
				}

				.new {
					font-size: 1rem;
					border-radius: 9px;
					border: 1px solid #000;
					padding: 0 7px;
					letter-spacing: -0.05px;
					display: inline-flex;
					align-items: center;
					height: 18px;
				}
			}

			&:first-child {
				width: 56.7777vw;

				.image {
					height: 104.4vh;
				}

				@include breakpoint(991px) {
					width: 100%;

					.image {
						height: 35vh;
					}
				}
			}

			&:nth-child(4) {
				width: 30vw;

				.image {
					height: 50vh;
				}

				@include breakpoint(991px) {
					width: 100%;

					.image {
						height: 35vh;
					}
				}
			}

			
		}

		.group {
			flex-grow: 1;
			width: auto;

			.grid__item {
				&:nth-child(1) {
					margin-left: 9.89vw;
					width: 23.4375vw;

					.image {
						height: 24.72vh;
					}
				}

				&:nth-child(2) {
					margin-top: 11.0185185185vh;
					margin-left: auto;
					width: 23.4375vw;

					.image {
						height: 65.648vh;
					}
				}

				@include breakpoint(991px) {
					&:nth-child(1) {
						margin-left: 0;
						width: 100%;
						margin-top: 10vh;
						margin-bottom: 10vh!important;

						.image {
							height: 35vh;
						}
					}

					&:nth-child(2) {
						margin-top: 10vh;
						margin-left: auto;
						width: 100%;

						.image {
							height: 35vh;
						}
					}
				}
			}
		}

		.center {
			padding-top: 10rem;
			display: flex;
			justify-content: center;
			width: 100%;

			@include breakpoint(991px) {
				flex-wrap: wrap;
				padding-top: 0;
			}

			.grid__item {
				width: 23.4375vw;

				@include breakpoint(991px) {
					width: 100%;
				}

				&:nth-child(1) {
					.image {
						height: 48.24vh;

						@include breakpoint(991px) {
							height: 35vh;
						}
					}
				}

				&:nth-child(2) {
					margin: 0 5.72916666667vw;
					
					.image {
						height: 26.6666666667vh;
					}

					@include breakpoint(991px) {
						margin: 10vh 0;

						.image {
							height: 35vh;
						}
					}
				}

				&:nth-child(3) {
					.image {
						height: 69.9074074074vh;

						@include breakpoint(991px) {
							height: 35vh;
						}
					}
				}
			}
		}



	}
}

@include breakpoint(991px) {
	.grid__item {
		margin: 5vh 0!important;

		&[data-id="1"] {
			margin-top: 0!important;
		}
	}

	.anim-mobile {
		@include transform(translate3d(0, 14rem, 0));
		opacity: 0;

		img {
			@include transform(scale(1.2));
		}

		&.anim {
			opacity: 1;
			@include transform(translate3d(0, 0, 0));
			@include transition(all 1.5s $smooth);

			img {
				@include transform(scale(1));
				@include transition(all 1.5s $smooth);
			}

			&.notransition {
				@include transition(none);

				img {
					@include transition(none);
				}
			}
		}
	}

	.no-anim-mobile {
		.anim-mobile {
			opacity: 1;
			@include transform(translate3d(0, 0, 0));

			img {
				@include transform(scale(1));
			}
		}
	}
}
