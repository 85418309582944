//       SUMMARY TABLE     
// =========================
// 1. Global
// =========================


// 1. Global
// =========================

html {
	font-size: 10px;
}

body {
	font-family: 'Saans', 'Arial', serif;
	font-size: 1.6rem;
	line-height: 1.5;
	color: #000;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
	text-decoration: none;

	&:hover {
		text-decoration: none;
	}
}

.title {
	font-size: 10.8rem;
	font-family: 'SaansMedium';
	line-height: 1;
	letter-spacing: -5px;

	@include breakpoint(991px) {
		font-size: 8rem;
	}
}

h3 {
	font-size: 1.5vw;
	line-height: 1.8vw;
	letter-spacing: -1.18px;

	@include breakpoint(991px) {
		font-size: 2.6rem;
		line-height: 2.8rem;
	}
}