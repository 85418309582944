//       SUMMARY TABLE     
// =========================
// 1. Common
// =========================


// 1. Common
// =========================

.page {
	position: relative;
	z-index: 1;
	padding-top: 20rem;

	@include breakpoint(991px) {
		padding-top: 14rem;
	}
}

.full {
	display: block;
	height: 100%;
	width: 100%;
	object-fit: cover;
	object-position: center;
}

.ml-auto {
	margin-left: auto;
}

.layout-1 {
	width: 24%;

	.img-container {
		height: 28vw;
		position: relative;

		img, video {
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	@include breakpoint(991px) {
		width: 100%;

		.img-container {
			height: 28rem;
		}
	}
}

.layout-2 {
	display: flex;
	padding-bottom: 4rem;

	& > div {
		img, video {
			position: absolute;
			top: 0;
			left: 0;
		}

		.img-container {
			position: relative;
		}

		&:first-child {
			width: calc(76% - 2rem);

			.img-container {
				padding-bottom: 60%;
			}
		}

		&:last-child {
			margin-left: auto;
			width: 24%;

			.img-container {
				padding-bottom: 100%;
			}
		}
	}

	@include breakpoint(991px) {
		flex-wrap: wrap;

		& > div {
			&:first-child {
				width: 100%;
				margin-bottom: 4rem;

				.img-container {
					padding-bottom: 28rem;
				}
			}

			&:last-child {
				width: 100%;

				.img-container {
					padding-bottom: 28rem;
				}
			}
		}
	}
}

.layout-3 {
	margin-bottom: 10rem;
	width: 48%;

	.img-container {
		height: 44vw;
	}

	@include breakpoint(991px) {
		width: 100%;

		.img-container {
			height: 28rem;
		}
	}
}


.legend {
	padding-top: 1rem;
	display: flex;
	color: #BBBBBB;
	font-size: 1.4rem;
	letter-spacing: -0.21px;
	align-items: center;

	svg {
		display: block;
	}

	span {
		display: block;
		margin-left: 4rem;
	}

	@include breakpoint(991px) {
		font-size: 1.2rem;

		span {
			margin-left: 2rem;
		}
	}
}



.text-anim {
	opacity: 0;
	visibility: hidden;
	@include transform(translate3d(0, 3rem, 0));

	&.anim {
		opacity: 1;
		visibility: visible;
		@include transform(translate3d(0, 0, 0));
		@include transition(all 1.4s $smooth .4s);
	}
}

.img-anim {
	.img-container {
		clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
		opacity: 0;
		visibility: hidden;
		@include transform(translate3d(0, 5rem, 0));
	}

	&.anim {
		.img-container {
			opacity: 1;
			visibility: visible;
			@include transform(translate3d(0, 0, 0));
			@include transition(all 1.4s $smooth);
		}
	}

	&.img-anim--delay.anim {
		.img-container {
			@include transition(transform 1.4s $smooth .2s, opacity 1.4s $smooth .2s, visibility 1.4s $smooth .2s, clip-path 1.4s $smooth);
		}
	}

	&:hover .img-container {
		clip-path: polygon(2% 2%, 98% 2%, 98% 98%, 2% 98%);
	}
}



.cursor {
	height: 2rem;
	width: 2rem;
	position: fixed;
	z-index: 500;
	pointer-events: none;
	top: -1rem;
	left: -1rem;
	will-change: transform;
	@include transform(translate(-100px, -100px));
	@include transition(transform 0.9s $smooth, opacity 0.3s $smooth);

	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		border-radius: 50%;
		background-color: #e4e4e4;
		opacity: 0.4;
		@include transition(all 0.4s $smooth);
	}

	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		height: 180%;
		width: 180%;
		border-radius: 50%;
		border: 1px solid #000;
		@include transform(translate3d(-50%, -50%, 0) scale(1.2));
		@include transition(all 0.4s $smooth);
		opacity: 0;
		visibility: hidden;
	}

	svg {
		opacity: 0;
		visibility: hidden;
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 1;
		@include transform(scale(0) translate(-50%, -50%) rotate(45deg));
		@include transition(all 0.6s $smooth);
	}

	&.hover {
		&:after {
			background-color: #000;
			opacity: 0.8;
			@include transform(scale(1.3));
		}

		svg {
			opacity: 1;
			visibility: visible;
			@include transform(scale(1) translate(-50%, -50%) rotate(0));
		}
	}

	span {
		display: block;
		position: absolute;
		font-size: 1.2rem;
		width: 20rem;
		left: 4rem;
		top: 0rem;
		opacity: 0;
		visibility: hidden;
		@include transform(translate3d(2rem, 0, 0) scale(1.1));
		@include transition(all .6s $custom);
	}

	&.hover-case {
		&:after {
			background-color: #000;
			opacity: 0.8;
			@include transform(scale(1.3));
		}

		&:before {
			opacity: 1;
			visibility: visible;
			@include transform(translate3d(-50%, -50%, 0));
		}

		span {
			opacity: 1;
			visibility: visible;
			@include transform(translate3d(0, 0, 0));
		}

		svg {
			opacity: 1;
			visibility: visible;
			@include transform(scale(1) translate(-50%, -50%) rotate(0));
		}
	}
}

.mango-overlay {
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  height: 100%;
  width: 100%;
  transition: opacity 0.3s ease 0.3s;
}
.mango-image--open {
  position: relative;
  cursor: zoom-out !important;
}
.mango--open .mango-overlay {
  cursor: zoom-out;
  opacity: 1;
  transition: opacity 0.1s ease;
}
.mango-image {
	cursor: pointer;
  transition: transform 0.3s ease;
}

[data-mango] {
	visibility: visible!important;
}

.img-container {
	overflow: hidden;
}

.img-container:after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: #000;
	opacity: 0;
	pointer-events: none;
	@include transition(all 0.4s $smooth);
}

.img-container:hover:after {
	opacity: 0.2;
}
