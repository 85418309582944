//       SUMMARY TABLE     
// =========================
// 1. Global
// =========================


// 1. Global
// =========================

.menu {
	position: fixed;
	bottom: 4vh;
	left: 0;
	right: 0;
	margin: auto;
	border-radius: 33px;
	height: 4.8rem;
	z-index: 15;
	width: 9.8rem;
	overflow: hidden;
	background-color: #fff;
	opacity: 0;
	@include transition(transform 1s $smooth);
	transform-origin: bottom center;

	&.hide {
		@include transform(translateY(10rem));
	}

	@include breakpoint(991px) {
		background-color: transparent;
	}

	.navigation {
		height: 100%;
	}

	.navigation__item {
		height: 100%;
		display: flex;
		align-items: center;
		font-size: 1.4rem;
		letter-spacing: -0.05px;
		width: 100%;
		justify-content: center;
		@include transition(background-color 0.4s $smooth);

		&.work-item {
			background-color: #000;
			color: #A9A9A9;
		}

		.text {
			height: 2.1rem;
			overflow: hidden;
			width: 4.2rem;
			@include transition(all 1s $custom);

			span {
				display: block;
				@include transition(all 1s $custom);

				&:last-child {
					width: 8rem;
				}
			}
		}

		.arrow {
			width: 1.25rem;
		  height: 1.25rem;
		  display: inline-block;
		  position: relative;
		  margin-left: 0.2rem;
		  @include transform(rotate(90deg) translateZ(0));

		  &.top {
		  	span:nth-child(4) {   
	        transform: rotate(-45deg);
		    	transition: transform 2s $smooth 1s;
	      }
      
	      span:nth-child(5) {
	        transform: rotate(45deg);
		    	transition: transform 2s $smooth 1s;
	      }
			}

		  &.bottom {
		  	span:nth-child(1) {   
	        transform: rotate(-45deg);
		    	transition: transform 2s $smooth 1s;
	      }
      
	      span:nth-child(2) {
	        transform: rotate(45deg);
		    	transition: transform 2s $smooth 1s;
	      }
			}

			span {
				position: absolute;
		    height: .1rem;
		    background-color: #BDBBB6;
		    transition: transform 1s $smooth;

		    &:nth-child(1),  
		    &:nth-child(2),    
		    &:nth-child(4),    
		    &:nth-child(5) {   
		      width: .625rem;
		      top: .625rem;
				}

				&:nth-child(1) {  
		      right: 0;
		      transform-origin: bottom right;
		    }

		    &:nth-child(2) {    
		      right: 0;
		      transform-origin: top right;
		    }

		    &:nth-child(3) {  
		      width: 1.25rem;
		      top: .625rem;
		    }

		    &:nth-child(4) {   
		      left: 0;
		      transform-origin: bottom left;
		    }

		    &:nth-child(5) {   
		      left: 0;
		      transform-origin: top left;
		    }
		  }
		}
		
		&.translate {
			.text {
				span {
					&:first-child {
						margin-top: -2.1rem;
					}
				}
			}
		}

		&.translated {
			.text {
				width: 8rem;

				span {
					&:first-child {
						margin-top: -4.2rem;
					}
				}
			}
		}
	}

	@media screen and (min-width: 992px) {
		&:hover {
			@include transform(scale(1.2));

			.navigation__item {
				background-color: #222;
			}
		}
	}
}