//       SUMMARY TABLE     
// =========================
// 1. Global
// =========================


// 1. Global
// =========================


.case {
	background-color: #fff;
	padding-top: 12rem;
	position: relative;

	@include breakpoint(991px) {
		padding-top: 10rem;
	}

	&:before {
		content: '';
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: #fff;
		z-index: 20;
		pointer-events: none;
	}

	&.ready {
		&:before {
			opacity: 0;
			visibility: hidden;
			@include transition(all .2s $smooth 0.2s);
		}
	}

	&__details {
		left: 0;
		width: 100%;
		position: absolute;
		top: 65vh;
		@include transform(translateY(calc(-100% - 1.4rem)));
		opacity: 0;

		.container {
			display: flex;
			align-items: center;

			div {
				&:first-child {
					width: 35%;
					letter-spacing: -0.5px;
					font-size: 1.1rem;
				}

				&:nth-child(2) {
					font-family: 'SaansMedium';
					letter-spacing: -0.5px;
				}

				&:last-child {
					font-size: 1.1rem;
					margin-left: auto;
					letter-spacing: -0.2px;
				}
			}

			a {
				color: #1E1E1E;
			}
		}

		@include breakpoint(991px) {
			top: 80vh;
			@include transform(translateY(-150%));

			.container {
				flex-wrap: wrap;
				justify-content: space-between;

				div {
					&:first-child {
						width: auto;
						margin-left: auto;
						text-align: right;
					}

					&:nth-child(2) {
						width: auto;
						font-size: 1.4rem;
					}

					&:last-child {
						display: none;
						font-size: 1.2rem;
						width: 100%;
						text-align: right;
						margin-top: 32vh;
					}
				}
			}
		}
	}

	&.notransition {
		&:before {
			display: none;
		}
	}

	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: #000;
		opacity: 0;
		pointer-events: none;
	}

	&.fade-black {
		&:after {
			opacity: 1;
			@include transition(opacity 3s $smooth 0.4s);
		}
	}

	&__close {
		position: fixed;
		top: 2rem;
		right: 2rem;
		z-index: 11;
		color: #000;
		font-weight: 600;
	}

	&__footer {
		height: 100vh;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;

		@include breakpoint(991px) {
			height: 90vh;
			align-items: flex-start;
			padding-top: 30vh;
		}

		&__bottom {
			display: flex;
			align-items: center;
			position: absolute;
			bottom: 1.4rem;
			left: 0;
			width: 100%;

			@include breakpoint(991px) {
				justify-content: space-between;
				bottom: 12.5rem;
			}

			& > p {
				color: #A6A6A6;
				font-size: 1rem;
				font-family: 'SaansMedium';
				letter-spacing: -0.5px;

				@include breakpoint(991px) {
					display: none;
				}
			}

			.links {
				color: #A9A9A9;
				font-size: 1.6rem;
				line-height: 1;
				position: absolute;
				left: 50%;
				@include transform(translateX(-50%));
				letter-spacing: -0.2px;
				font-family: 'TimesNow';

				a {
					color: #A9A9A9;
					@include transition(color 0.4s $smooth);

					&:hover {
						color: #000;
					}
				}
			}

			.next {
				margin-left: auto;
				color: #000;
				font-size: 1rem;
				line-height: 1;
				letter-spacing: -0.2px;

				@include breakpoint(991px) {
					display: none;
				}
			}
		}

		&__main {
			text-align: center;

			& > span {
				display: none;

				@include breakpoint(991px) {
					display: table;
					margin: 0 auto;
					color: #1E1E1E;
					text-transform: uppercase;
					font-family: 'SaansMedium';
					font-size: 1.1rem;
					letter-spacing: -0.5%;
				}
			}

			a {
				text-transform: uppercase;
				display: inline-flex;
				align-items: center;
				font-family: 'Saans';
				font-size: 1.1rem;
				letter-spacing: -0.5%;
				line-height: 1;
				color: #1E1E1E;
				padding: 0 2.2rem;
				border-radius: 25px;
				border: 1px solid #000;
				height: 4rem;
				@include transition(all 0.4s $smooth);

				@include breakpoint(991px) {
					height: 3rem;
					padding: 0 1.8rem;
				}

				svg {
					display: block;
					margin-left: 0.6rem;

					path {
						@include transition(all 0.4s $smooth);
					}
				}

				@media screen and (min-width: 992px) {
					&:hover {
						background-color: #000;
						color: #fff;

						svg {
							path {
								stroke: #fff;
							}
						}
					}
				}
			}

			#logo-footer {
				width: 8rem;
				height: 1.4rem;
				display: table;
				margin: 0 auto;

				@include breakpoint(991px) {
					position: absolute;
					bottom: 3rem;
					width: 100%;
					height: 6.3rem;
					left: 0;
				}
			}

			h2 {
				font-family: 'TimesNow';
				font-size: 4.2rem;
				line-height: 4.6rem;
				letter-spacing: -0.84px;
				color: #1E1E1E;
				width: 80rem;
				margin: 1rem 0 1.6rem 0;

				@include breakpoint(991px) {
					font-size: 2rem;
					line-height: 2.4rem;
					width: 100%;
				}
			}
		}
	}

	.head {
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin-bottom: 120vh;
		position: relative;
		align-items: center;
		-webkit-backface-visibility: hidden;
		height: calc(65vh - 24rem);

		@include breakpoint(1680px) {
			margin-bottom: 122vh;
		}

		.content {

			img {
				display: table;
				margin: 0 auto;

				@include breakpoint(991px) {
					width: 4.5rem;
				}
			}
		}

		@include breakpoint(991px) {
			flex-wrap: wrap;
			margin-bottom: 125vh;
			justify-content: center;
			height: calc(80vh - 24rem);
		}

		h1 {
			font-family: 'TimesNow';
			font-size: 13.2rem;
			letter-spacing: -0.6px;
			line-height: 1;
			color: #1E1E1E;
			position: relative;

			.new {
				font-size: 1rem;
				border-radius: 9px;
				border: 1px solid #000;
				padding: 0 7px;
				letter-spacing: -0.05px;
				display: inline-flex;
				align-items: center;
				height: 18px;
				position: absolute;
				font-family: 'Saans';
				text-transform: uppercase;
				top: 2rem;
				margin-left: 2rem;
			}

			@include breakpoint(991px) {
				font-size: 4.8rem;
				width: 100%;
				text-align: center;
				line-height: 2.8rem;

				& > span:not(.new) {
					display: block;
				}

				.new {
					position: relative;
					top: auto;
					margin-left: 0;
				}
			}

			// font-family: 'SaansMedium';
			// font-size: 4.6vw;
			// line-height: 1;
			// letter-spacing: -5px;
			// display: flex;
			// align-items: flex-start;

			// sup {
			// 	font-size: 1.8rem;	
			// 	margin-left: 2rem;
			// 	letter-spacing: 0;
			// 	font-family: 'Saans';
			// }

			// @include breakpoint(991px) {
			// 	font-size: 3rem;
			// 	letter-spacing: 0;
			// 	width: 100%;

			// 	sup {
			// 		font-size: 1.2rem;
			// 		margin-left: 1rem;
			// 	}
			// }
		}
	}

	.img {
		background-color: #D9D9D9;
		height: 100vh;
		position: absolute;
		top: 65vh;
		width: 100%;
		left: 0;
		right: 0;
		margin: auto;

		@include breakpoint(991px) {
			top: 80vh;
		}
	}

	&__intro {
		margin-bottom: 16rem;
		width: 48%;
		text-indent: 25%;

		@include breakpoint(991px) {
			width: 100%;
			text-align: left;
			margin-bottom: 8rem;
		}
	}

	&__info {
		display: flex;
		padding: 19rem 0 4rem 0;

		div {
			color: #0D0D0D;
			width: 24%;
			padding-right: 2%;

			p {
				font-size: .8vw;
				line-height: 1.1vw;
			}

			&:first-child {
				color: #5B5B5B;
				width: 52%;
				text-transform: uppercase;
				
				p {
					font-size: .8vw;
				}
			}
		}

		@include breakpoint(991px) {
			flex-wrap: wrap;
			padding-top: 8rem;
			padding-bottom: 8rem;

			div {
				width: 100%;
				padding: 0;

				p {
					font-size: 1.4rem;
					line-height: 1.4rem;
				}

				&:first-child {
					p {
						font-size: 1.2rem;
						margin-bottom: 2rem;
					}
				}
			}
		}
	}

	&__information {
		display: flex;
		padding: 2rem 0 6rem 0;

		div {
			color: #0D0D0D;
			width: 48%;

			p {
				font-size: .88vw;
				line-height: 1.1vw;
			}

			&:first-child {
				color: #5B5B5B;
				width: 52%;
				text-transform: uppercase;
				
				p {
					font-size: .8vw;
				}
			}
		}

		@include breakpoint(991px) {
			flex-wrap: wrap;
			padding-top: 8rem;
			padding-bottom: 8rem;

			div {
				width: 100%;
				padding: 0;

				p {
					font-size: 1.4rem;
					line-height: 1.4rem;
				}

				&:first-child {
					p {
						font-size: 1.2rem;
						margin-bottom: 2rem;
					}
				}
			}
		}
	}
}